import { useOrder } from 'components/OrderContext';
import { Form } from 'formik';
import People from '../People';
import PaymentInfo from '../PaymentInfo';
import Waitlist from '../Waitlist';
import config from 'config';
const { WAITLIST_MODE } = config;

export default function FormContents({ formikRef }) {
  const { updateOrder, currentPage, setCurrentPage } = useOrder();

  // console.log('FormContents rendered');

  function handleClickBackButton() {
    if (formikRef.current) {
      const values = formikRef.current.values;
      updateOrder(values);
      formikRef.current.setSubmitting(false);
      setCurrentPage(currentPage - 1);
    }
  }

  return(
    <Form spellCheck='false'>
      {currentPage === 1 && <People />}
      {currentPage === 2 && WAITLIST_MODE && <Waitlist handleClickBackButton={handleClickBackButton} />}
      {currentPage === 2 && !WAITLIST_MODE && <PaymentInfo handleClickBackButton={handleClickBackButton} />}
    </Form>
  );
}
