import { useTheme } from '@mui/system';
import { Box } from '@mui/material';
import { mailtoLink } from "utils";
import { StyledLink, Paragraph } from 'components/Layout/SharedStyles';
import config from 'config';
const { EMAIL_CONTACT, TECH_CONTACT, EVENT_TITLE, WAITLIST_MODE } = config;

export default function IntroHeader() {
  const theme = useTheme();

  return (
    <>
      {WAITLIST_MODE &&
        <Box sx={{ my: 4, p: 2, backgroundColor: theme.palette.background.sticky, display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
          <strong>{EVENT_TITLE} is full; sign up here for the waitlist.</strong>
        </Box>
      }

      <Paragraph>
        Join us at <StyledLink to='https://www.queercontradance.org/queercamp.html'>Queer Contra Dance Camp 2025</StyledLink>!
        Camp is April 11-13, 2025 at Monte Toyon in Aptos, CA.
        It'll be another amazing weekend of contra dance, three days with two great bands, the Sage Thrashers and Starling, and two fantastic callers, Angela DeCarlis and Sue Rosen.
        This year's theme is Sparkle and Shine!
      </Paragraph>
      <Paragraph>
        Registration system questions? Email <StyledLink to={mailtoLink(TECH_CONTACT)}>{TECH_CONTACT}</StyledLink>.<br />
        Any other questions? Email <StyledLink to={mailtoLink(EMAIL_CONTACT)}>{EMAIL_CONTACT}</StyledLink>.
      </Paragraph>
    </>
  );
}
