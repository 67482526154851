import { useState, useEffect, useMemo } from 'react';
import { InputAdornment, Box, Tab, Tabs, FormControlLabel, Checkbox } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { useFormikContext } from 'formik';
import { scrollToTop, clamp } from 'utils';
import { StyledPaper, Title, Paragraph } from 'components/Layout/SharedStyles';
import { PaymentExplanation } from 'components/Static/PaymentExplanation';
import { RightAlignedInput } from '../Input';
import { useOrder } from 'components/OrderContext';
import NavButtons from 'components/NavButtons';
import config from 'config';
const { DEPOSIT_COST, ADMISSION_COST_RANGE, PAYMENT_DUE_DATE } = config;

export default function PaymentInfo({ handleClickBackButton }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  const { order, updateOrder } = useOrder();
  const [paymentTab, setPaymentTab] = useState(order.deposit > 0 ? 'deposit' : 'fullpayment');
  const [coverFees, setCoverFees] = useState(order.fees > 0);
  const isMultiplePeople = order.people.length > 1;
  
  useEffect(() => { scrollToTop(); },[])

  const admissionTotal = useMemo(() => {
    return values.people.reduce((total, person) => total + clampAdmission(person.admission), 0);
  }, [values.people]);

  const depositTotal = useMemo(() => {
    return DEPOSIT_COST * order.people.length;
  }, [order.people.length]);

  const total = useMemo(() => {
    return paymentTab === 'deposit' ? depositTotal : admissionTotal;
  }, [paymentTab, depositTotal, admissionTotal]);

  const fees = useMemo(() => {
    return (0.0245 * total + 0.5).toFixed(2);
  }, [total]);

  useEffect(() => {
    updateOrder({
      total,
      fees: coverFees ? parseFloat(fees) : 0
    });
  }, [total, fees, coverFees, updateOrder]);

  const handlePaymentTab = (_, newTab) => {
    setFieldValue('deposit', newTab === 'deposit' ? order.people.length * DEPOSIT_COST : 0);
    setPaymentTab(newTab);
  };

  function updateAdmissionCostValue(event) {
    const { name, value } = event.target;
    setFieldValue(name, clampAdmission(value));
    handleBlur(event); // bubble up to formik
  }

  return (
    <section className='PaymentInfo'>

      <PaymentExplanation />

      <div className='admissions-section'>
        <StyledPaper className='admissions-cost'>

          <Title>Sliding scale</Title>
          <Paragraph>Please read the sliding scale and deposit explanations above.</Paragraph>

          <TabContext value={paymentTab}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={paymentTab} onChange={handlePaymentTab} aria-label="payment options tabs">
                <Tab label="Full Payment" value="fullpayment" sx={{ '&:hover': { bgcolor: 'primary.light', color: 'primary.contrastText' } }} />
                <Tab label="Deposit" value="deposit" sx={{ '&:hover': { bgcolor: 'primary.light', color: 'primary.contrastText' } }} />
              </Tabs>
            </Box>

            <TabPanel value="fullpayment" sx={{ pl: 1, pr: 0 }}>
              {isMultiplePeople && <Paragraph>How much is each person able to pay?</Paragraph>}
              {order.people.map((person, index) =>
                <RightAlignedInput
                  key={index}
                  sx={{ width: '5em', mb: 1 }}
                  label={isMultiplePeople ? `${person.first} ${person.last}` : 'How much are you able to pay?'}
                  name={`people[${index}].admission`}
                  type='pattern'
                  pattern='###'
                  range={ADMISSION_COST_RANGE}
                  onBlur={updateAdmissionCostValue}
                  InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                />
              )}
            </TabPanel>

            <TabPanel value="deposit" sx={{ pl: 1, pr: 0 }}>
              <Paragraph>A deposit of ${DEPOSIT_COST} per person is required to reserve your spot.</Paragraph>
              <Paragraph color='warning.main' sx={{ my: 2, fontWeight: 'bold' }}>The balance of the payment will be due by {PAYMENT_DUE_DATE}.</Paragraph>
            </TabPanel>

          </TabContext>

          <FormControlLabel
            control={<Checkbox checked={coverFees} onChange={(e) => setCoverFees(e.target.checked)} />}
            label={`I would like to add ${fees} to cover the PayPal transaction fees.`}
          />

        </StyledPaper>

      </div>

      <NavButtons
        backButtonProps = {{ text: 'Back', onClick: handleClickBackButton }}
        nextButtonProps = {{ text: 'Checkout' }}
      />

    </section>
  );
}

function clampAdmission(value) {
  return clamp(parseInt(value) || ADMISSION_COST_RANGE[0], ADMISSION_COST_RANGE);
}
